<template>

  <div><h1 class="heading">Заработать баллы</h1></div>
  <div class="sub-menu-links d-flex align-items-center mb-4">
    <router-link to="/sale/add/" class="ml-0 mr-5 menu-link">Добавить продажу</router-link>
    <router-link to="/sale/history/" class="menu-link active">История продаж</router-link>
  </div>


    <button class="show-returned"
            @click="toggleReturned"
            :class="{active: showReturned}">
      показать возвраты
      <span class="material-icons close-returned">cancel</span>
    </button>

    <template v-if="Object.keys(historyData).length > 0">
    <div class="items-wrapper" v-for="day in historyData" :key="day.id">
      <div class="item mb-4">

        <div class="item-info col-12 mb-2">
          <div class="item-info-date">{{ day.title }}</div>
          <div class="item-info-score">{{ day.totalScores }}
            <span class="material-icons star-icon">star</span>
          </div>
        </div>
        <div class="item-info col-12">

          <div data-bs-toggle="collapse" :data-bs-target="'#collapse-'+day.id" aria-expanded="false"
               class="item-info-dropdown">
            подробнее
            <span class="material-icons dropdown-icon">expand_more</span>
          </div>
          <div class="item-info-score-small">{{ day.successScores }} из {{ day.totalScores }}
            <span class="material-icons star-icon-small">star</span>
            <span class="material-icons check-icon-small">check_circle</span>
          </div>
        </div>

        <b-collapse :id="'collapse-'+day.id" class="sub-item-list col-12">
          <!-- item -->
          <div class="sub-item" v-for="sale in day.sales" :key="sale">
            <div class="row">
              <div class="sub-item-date col-6">
                <div class="sub-item-date-date mb-2">{{ sale.itemTitle }}</div>
                <div data-bs-toggle="collapse" :data-bs-target="'#collapse-'+sale.itemDigitalCode+'-inner'"
                     aria-expanded="false" class="sub-item-date-dropdown">
                  подробнее
                  <span class="material-icons dropdown-icon">expand_more</span>
                </div>
              </div>
              <div class="sub-item-info col-6">
                <div class="sub-item-info-status">
                  <div :class="'sub-item-info-status-'+sale.itemStatus">{{ sale.itemStatusValue }}</div>
                  <span class="material-icons" v-if="sale.itemStatus != 'returned'" :class="'icon alt check-icon-small-mobile-'+sale.itemStatus">check_circle</span>
                  <span class="material-icons" v-else :class="'icon alt check-icon-small-mobile-'+sale.itemStatus">cancel</span>
                </div>
                <div class="sub-item-info-score">{{ sale.itemScores }}
                  <span class="material-icons star-icon">star</span>
                </div>
              </div>
            </div>
            <b-collapse :id="'collapse-'+sale.itemDigitalCode+'-inner'" class="sub-item-details mt-3">
              <div class="sub-item-details-props">
                <div class="sub-item-details-props-name">Вид продукции:</div>
                <div class="sub-item-details-props-value">{{ sale.itemProductType }}</div>
              </div>
              <div class="sub-item-details-props">
                <div class="sub-item-details-props-name">Категория:</div>
                <div class="sub-item-details-props-value">{{ sale.itemCategory }}</div>
              </div>
              <div class="sub-item-details-props">
                <div class="sub-item-details-props-name">Марка:</div>
                <div class="sub-item-details-props-value">{{ sale.itemMark }}</div>
              </div>
              <div class="sub-item-details-props">
                <div class="sub-item-details-props-name">Артикул:</div>
                <div class="sub-item-details-props-value">{{ sale.itemArticle }}</div>
              </div>
              <div class="sub-item-details-props">
                <div class="sub-item-details-props-name">Цифровой уникальный код:</div>
                <div class="sub-item-details-props-value">{{ sale.itemDigitalCode }}</div>
              </div>
              <div class="sub-item-details-props">
                <div class="sub-item-details-props-name">Начислено баллов:</div>
                <div class="sub-item-details-props-value">{{ sale.itemScores }}
                  <span class="material-icons star-icon-small pr-1">star</span>
                  ({{ sale.userCurrentLevel }})
                </div>
              </div>
            </b-collapse>
          </div>
          <!-- end of item -->

        </b-collapse>
      </div>
    </div>


    <div class="pagination" v-if="pagination.links != undefined && pagination.links.length > 1">
      <a class="pagination-previous" v-if="pagination.prev_page_url != null" :href="pagination.prev_page_url"
         v-on:click.prevent.stop="changePage(pagination.prev_page_url)">
        <font-awesome-icon icon="chevron-left" class="icon alt"/>
      </a>
      <template v-for="link in pagination.links" :key="link">
        <a class="pagination-item" :class="{active:link.active}" :href="link.url"
           v-on:click.prevent.stop="changePage(link.url)" v-if="link.url">{{ link.label }}</a>
        <span v-else>{{ link.label }}</span>
      </template>
      <a class="pagination-next" v-if="pagination.next_page_url != null" :href="pagination.next_page_url"
         v-on:click.prevent.stop="changePage(pagination.next_page_url)">
        <font-awesome-icon icon="chevron-right" class="icon alt"/>
      </a>
    </div>
  </template>


  <template v-else>
    <div class="empty-history">Продаж еще не было</div>
  </template>


</template>

<script>


export default {
    name: "SaleHistory",
    data() {
        return {
            isActive: false,
            showReturned: false
        }
    },
    methods: {
        changePage(link) {
            this.$store.dispatch("historyData", {'link': link, 'returns': this.showReturned});
        },
        toggleReturned: function () {
            this.showReturned = (this.showReturned) ? this.showReturned = false : this.showReturned = true;
            this.$store.dispatch("historyData", {'returns': this.showReturned});
        }
    },
    mounted() {
        this.$store.dispatch("historyData", {});
    },
    computed: {
        historyData() {
            return this.$store.getters.getHistoryData;
        },
        pagination() {
            return this.$store.getters.getPagination;
        }
    },

}
</script>

<style scoped lang="scss">
.empty-history {
  font-weight: bold;
  font-size: 24px;
  color: #a2a3a5;
  display:flex;
  justify-content: center;
  margin-top:100px;
  margin-bottom:100px;
}
.item {
    background: #F7F7F7;
    padding: 15px 10px;
    border-radius: 8px;
}

.item-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-info-date, .sub-item-date-date {
    font-weight: bold;
}

.item-info-score {
    font-size: 16px;
    font-weight: bold;
  display: flex;
}

.star-icon {
    color: #ffc107;
    font-size: 22px;
    margin-left: 5px;
}

.item-info-dropdown {
    color: #71747A;
    font-size: 12px;
  display: flex;
}

.dropdown-icon {
    color: #71747A;
    font-size: 16px;
    transition: 0.2s ease;
}

.item-info-score-small, .sub-item-date-dropdown {
    color: #71747A;
    font-size: 12px;
    padding-right: 2px;
  display: flex;
}

.star-icon-small {
    color: #ffc107;
    font-size: 18px;
    margin-left: 5px;
}

.check-icon-small {
    color: #3663F2;
    font-size: 16px;
    margin-left: 3px;
}

.sub-item-list {
    margin-top: 15px;
}

.sub-item {
    padding: 15px 10px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 15px;
}
.item-info-dropdown.collapsed > .dropdown-icon,
.sub-item-date-dropdown.collapsed > .dropdown-icon {
    transform: rotate(0deg)
}

.item-info-dropdown > .dropdown-icon,
.sub-item-date-dropdown > .dropdown-icon {
    transform: rotate(-180deg)
}

.sub-item-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sub-item-info-status {
    margin-right: 30px;
}

.sub-item-info-status-success {
    padding: 5px 15px;
    background-color: #3663F2;
    border-radius: 100px;
    color: #ffffff;
    font-size: 12px;
}

.sub-item-info-status-confirmation {
    padding: 5px 15px;
    background-color: #F7F7F7;
    border-radius: 100px;
    color: #71747A;
    font-size: 12px;
}

.sub-item-info-status-returned {
    padding: 5px 15px;
    background-color: #EC5959;
    border-radius: 100px;
    color: #FFFFFF;
    font-size: 12px;
}

.sub-item-info-score {
    display: flex;
    align-items: center;
}

.sub-item-details-props {
    margin-bottom: 10px;
  display: flex;
}

.sub-item-details-props-name {
    font-size: 12px;
    line-height: 20px;
}

.sub-item-details-props-value {
    font-size: 14px;
    line-height: 20px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.detail-star-icon-small {
    color: #ffc107;
    font-size: 16px;
}

.check-icon-small-mobile-success {
    color: #ffffff;
    font-size: 16px;
    display: none;
}

.check-icon-small-mobile-confirmation {
    color: #71747A;
    font-size: 16px;
    display: none;
}

.check-icon-small-mobile-returned {
    color: #ff4444;
    font-size: 16px;
    display: none;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.pagination-next, .pagination-previous {
    color: #71747A;
    font-size: 12px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-item {
    font-size: 12px;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-item.active {
    box-shadow: 0px 1px 4px rgba(26, 26, 67, 0.1);
    font-weight: bold;
    color: #3663F2;
}

.show-returned {
    background-color: transparent;
    border: none;
    color: #737373;
    margin-bottom: 20px;
    padding: 5px 15px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-returned {
    display: none;
    margin-left: 5px;
    color: #3663F2;
  font-size:22px;
}

.show-returned.active {
    background-color: transparent;
    border: none;
    color: #737373;
    margin-bottom: 20px;
    background-color: #f7f7f7;
}

.show-returned.active > .close-returned {
    display: block
}

@media (max-width: 820px) {
    .sub-item-info-status {
        margin-right: 0px;
    }

    .sub-item-info-score {
        margin-bottom: 13px;
    }

    .sub-item-info-status-success {
        display: none;
    }

    .sub-item-info-status-confirmation {
        display: none;
    }

    .sub-item-info-status-returned {
        display: none;
    }

    .check-icon-small-mobile-success {
        color: #3663F2;
        font-size: 16px;
        display: block;
        margin-right: 2px;
    }

    .check-icon-small-mobile-confirmation {
        color: #C9CCD1;
        font-size: 16px;
        display: block;
        margin-right: 2px;
    }

    .check-icon-small-mobile-returned {
        color: #EC5959;
        font-size: 16px;
        display: block;
        margin-right: 2px;
    }

    .sub-item-info {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column-reverse;
    }

    .sub-item-date-date {
        font-size: 13px;
    }
}

@media (max-width: 536px) {
    .item-info {
        padding: 0 5px;
    }

    .sub-item-list {
        padding: 0px;
    }

    .sub-item-date {
        padding-right: 0px;
    }

    .sub-item-info-score {
        font-size: 14px;
    }

    .sub-item-details-props {
        display: flex;
        align-items: baseline;
        margin-bottom: 5px;
        padding-bottom: 5px;
        flex-direction: column;
        border-bottom: 1px solid #f7f7f7;
    }

    .sub-item-details-props:last-of-type {
        border-bottom: none;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .sub-item-details-props-value {
        font-size: 12px;
        margin-left: 0px;
      padding-left:0;
        font-weight: bold;
        display:flex;
       align-items:center;
    }
}
</style>
